import { useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Flex,
  Link,
} from '@chakra-ui/react';

import { customColors } from '../../helpers/theme';
import Logo from '../../public/images/logo.svg';
import { useUser } from '@store/User/UserContext';
import { BROWSE_COLLECTIONS_ROUTE } from '../../config/routes';
import { validateUrl } from '../../helpers/validateUrl';
import { useRudderstack } from '../../contexts/Rudderstack/RudderstackContext';

const Login = () => {
  const router = useRouter();
  const { userstate } = useUser();
  const [redirecting, setRedirecting] = useState(false);
  const rudderanalytics = useRudderstack(); //eslint-disable-line react-hooks/rules-of-hooks

  useEffect(() => {
    rudderanalytics?.page();
  }, [rudderanalytics]);

  useEffect(() => {
    if (!redirecting && router && userstate?.isLoggedIn) {
      setRedirecting(true);
      const { url, extension } = router.query;
      if (extension === '1') {
        if (url[0] !== '') {
          const supportedUrl = validateUrl(url[0]);
          if (supportedUrl) {
            router.push(url[0]);
          } else {
            router.push(BROWSE_COLLECTIONS_ROUTE);
          }
        } else {
          router.push(BROWSE_COLLECTIONS_ROUTE);
        }
      } else {
        router.push(BROWSE_COLLECTIONS_ROUTE);
      }
    }
  }, [redirecting, userstate, router]);

  return (
    <Box
      as="section"
      bgImage="url('/images/bannermonkey.png')"
      pl={4}
      py={12}
      className="login">
      <Container mr="auto" ml="unset">
        <Box
          maxW="508px"
          px="45px"
          py="32px"
          bg="white"
          borderRadius="md"
          textAlign="center">
          <Image src={Logo} alt="logo" />

          <Flex direction="column" justify="center" minH="calc(100vh - 289px)">
            <Heading as="h2" fontSize={['2xl', '7xl']} mb="16px">
              Welcome!
              <br /> Please log in first
            </Heading>
            <Text textColor={customColors.grey} p={1}>
              In order to log In to Nval you need an account.
            </Text>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a href="/api/auth/login">
              <Button
                colorScheme="brand"
                id="loginbtn"
                variant="solid"
                size="md"
                mt="32px"
                isFullWidth>
                {' '}
                Log in
              </Button>
            </a>
          </Flex>

          <Text textColor={customColors.grey} maxW="300px" mx="auto" mt="2">
            By logging in you’re agreeing to our{' '}
            <Link
              isExternal
              fontWeight="semibold"
              href="https://www.nval.com/TOS">
              Terms & Conditions
            </Link>
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
