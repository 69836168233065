const supportedUrl = [
  /^(http|https):\/\/opensea.io\/[^ "]+$/,
  /^(http|https):\/\/looksrare.org\/[^ "]+$/,
  /^(http|https):\/\/nftrade.com\/[^ "]+$/,
  /^(http|https):\/\/niftygateway.com\/[^ "]+$/,
  /^(http|https):\/\/rarible.com\/[^ "]+$/,
];

export const validateUrl = (url: string) => {
  let supporteduri = supportedUrl.filter(supporteduri => {
    return supporteduri.test(url);
  });
  if (supporteduri.length >= 1) {
    return true;
  } else {
    return false;
  }
};
